exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-construction-js": () => import("./../../../src/pages/construction.js" /* webpackChunkName: "component---src-pages-construction-js" */),
  "component---src-pages-consultationhub-js": () => import("./../../../src/pages/consultationhub.js" /* webpackChunkName: "component---src-pages-consultationhub-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-hub-index-js": () => import("./../../../src/pages/InformationHub/index.js" /* webpackChunkName: "component---src-pages-information-hub-index-js" */),
  "component---src-pages-morven-offshore-wind-farm-index-js": () => import("./../../../src/pages/MorvenOffshoreWindFarm/index.js" /* webpackChunkName: "component---src-pages-morven-offshore-wind-farm-index-js" */),
  "component---src-pages-our-work-so-far-commercial-fisheries-shipping-and-navigation-js": () => import("./../../../src/pages/our-work-so-far/commercial-fisheries-shipping-and-navigation.js" /* webpackChunkName: "component---src-pages-our-work-so-far-commercial-fisheries-shipping-and-navigation-js" */),
  "component---src-pages-our-work-so-far-index-js": () => import("./../../../src/pages/our-work-so-far/index.js" /* webpackChunkName: "component---src-pages-our-work-so-far-index-js" */),
  "component---src-pages-our-work-so-far-next-steps-js": () => import("./../../../src/pages/our-work-so-far/next-steps.js" /* webpackChunkName: "component---src-pages-our-work-so-far-next-steps-js" */),
  "component---src-pages-our-work-so-far-supporting-the-local-regional-and-national-economy-js": () => import("./../../../src/pages/our-work-so-far/supporting-the-local-regional-and-national-economy.js" /* webpackChunkName: "component---src-pages-our-work-so-far-supporting-the-local-regional-and-national-economy-js" */),
  "component---src-pages-proposals-index-js": () => import("./../../../src/pages/proposals/index.js" /* webpackChunkName: "component---src-pages-proposals-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

